<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Ürün Ekle') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Firma ad -->
          <b-form-group
            :label="$t('Ürün')"
            label-for="product"
          >
            <vue-autosuggest
              v-model="productData.name"
              :suggestions="productSuggest.filteredProducts"
              :input-props="productSuggest.inputProps"
              @selected="productSelected($event,index)"
              @input="productOnInputChanged"
            >
              <template slot-scope="{suggestion}">
                <div class="d-flex align-items-center">

                  <div class="detail ml-50">
                    <b-card-text class="mb-0">
                      {{ suggestion.item.name }}
                    </b-card-text>

                  </div>
                </div>
              </template>
            </vue-autosuggest>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Kaydet') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Vazgeç') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import router from '@/router'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    VueAutosuggest,
    BButton,

    // Form Validation
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isAddNewProductSidebarActive',
    event: 'update:is-add-new-product-sidebar-active',
  },
  props: {
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      productSuggest: {
        limit: 10,
        filteredProducts: [],
        inputProps: {
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: "Ürün Adı Giriniz'",

        },

      },
    }
  },
  methods: {
    productSelected(option) {
      this.productData.name = option.item.name
    },
    productOnInputChanged(text) {
      if (text === '' || text === undefined) {
        return
      }
      store.dispatch('app/suggestPProducts', { name: text, limit: this.productSuggest.limit })
        .then(response => {
          this.productSuggest.filteredProducts = [{
            data: response.data.data,
          }]
        })
    },
  },
  setup(props, { emit }) {
    const blankProductData = {
      name: '',
    }

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const onSubmit = () => {
      store.dispatch('app-p-product/addProduct', productData.value)
        .then(response => {
          emit('update:is-add-new-product-sidebar-active', false)
          router.push({ name: 'p-products-edit', params: { id: response.data.data.id } })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)

    return {
      productData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
