import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refProductListTable = ref(null)

  // Table Handlers
  const tableColumns = [

    { key: 'invoice_type', label: 'Fatura Türü', sortable: true },
    { key: 'buy_date', label: 'Alım Tarihi', sortable: false },
    { key: 'invoice_number', label: 'Fatura No', sortable: false },
    { key: 'expense_type', label: 'Harcama Türü', sortable: false },
    { key: 'product_name', label: 'Ürün Adı', sortable: false },
    { key: 'currency', label: 'Döviz', sortable: false },
    { key: 'exchange_rate', label: 'Döviz Kuru', sortable: false },

    { key: 'piece', label: 'Miktar', sortable: false },
    { key: 'currency_unit_price', label: 'Döviz Birim Fiyat', sortable: false },
    { key: 'tl_unit_price', label: 'Tl Döviz Fiyat', sortable: false },
    { key: 'invoice_total', label: 'Fatura Toplam', sortable: false },
    { key: 'description', label: 'Açıklama', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const d = new Date()
  const thisYear = d.getFullYear()
  const targetMonth = ref(null)

  const targetYear = ref(thisYear)
  const dataMeta = computed(() => {
    const localItemsCount = refProductListTable.value ? refProductListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchData = () => {
    refProductListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('app-general-expense/fetchProducts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
        targetYear: targetYear.value,
        targetMonth: targetMonth.value,
      })
      .then(response => {
        const products = response.data.data.products.data
        const { total } = response.data.meta

        callback(products)
        totalProducts.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching product list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    targetYear,
    targetMonth,
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProductListTable,

    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
